/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
const margin = {
  top: 40,
  right: 20,
  bottom: 30,
  left: 50,
};

const tooltip = {
  width: 100,
  height: 100,
  x: 10,
  y: -30,
};

const dateToString = day => {
  if (!(day instanceof Date)) return day;

  const dd = String(day.getDate()).padStart(2, '0');
  const mm = String(day.getMonth() + 1).padStart(2, '0');
  const yyyy = day.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

// set the dimensions and margins of the graph
const width = 960 - margin.left - margin.right;
const height = 210 - margin.top - margin.bottom;

const drawGraph = (method, query, attribute, title, urlDate) => {
  d3.selection.prototype.moveToFront = function() {
    return this.each(function() {
      this.parentNode.appendChild(this);
    });
  };

  // parse the date / time
  const parseTime = d3.timeParse('%Y-%m-%d');
  const formatPercent = d3.format('.1%');
  const bisectDate = d3.bisector(d => d.day).left;
  const dateFormatter = day => {
    if (!(day instanceof Date)) return day;

    return I18n.l('time.formats.date_month_year', day);
  };

  // set the ranges
  const x = d3.scaleTime().range([0, width]);
  const y = isRating(attribute)
    ? d3.scaleOrdinal().range(d3.range(height, 0, -height / moodys.length))
    : d3.scaleLinear().range([height, 0]);

  // define the line
  const valueline = d3
    .line()
    .x(d => x(d.day))
    .y(d => y(d[attribute]))
    .curve(d3.curveStep);

  const widthCalculated = width + margin.left + margin.right;
  const heightCalculated = height + margin.top + margin.bottom;

  d3.select('.infobox svg').remove();

  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;

  let svg;
  if (isIE) {
    const newHeight = heightCalculated + 150;
    svg = d3
      .select('.infobox')
      .append('svg')
      .attr('height', `${newHeight}px`)
      .attr('viewBox', `0 0 ${widthCalculated} ${heightCalculated}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
  } else {
    svg = d3
      .select('.infobox')
      .append('svg')
      .attr('viewBox', `0 0 ${widthCalculated} ${heightCalculated}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
  }

  svg
    .append('text')
    .attr('id', 'graph-loading')
    .attr('x', width / 2)
    .attr('y', height / 2)
    .attr('text-anchor', 'middle')
    .style('fill', 'rgb(8, 48, 107)')
    .text('Loading...');

  const dataGraphLoaded = (error, data) => {
    if (attribute === 'regional_yield') {
      data = data.map(item => ({
        regional_yield: item.regionalBondYields[0].yield,
        day: item.day,
      }));

      const map = new Map();
      const result = [];

      data.forEach(item => {
        const date = new Date(item.day);
        const dateStr = dateToString(date);

        if (!map.has(dateStr)) {
          map.set(dateStr, item.regional_yield);

          const obj = {
            day: dateStr,
            regional_yield: item.regional_yield,
          };

          result.push(obj);
        }
      });
      data = Array.from(result);
    } else if (attribute === 'rating') {
      const exclude = ['N/A', 'NR', 'WR'];
      data = data.filter(x => !exclude.includes(x.rating));
    }

    d3.select('#graph-loading').remove();

    if (error) throw error;

    if (data.length === 0) {
      svg
        .append('text')
        .attr('id', 'graph-loading')
        .attr('x', width / 2)
        .attr('y', height / 2)
        .attr('text-anchor', 'middle')
        .style('fill', 'rgb(8, 48, 107)')
        .text('No data available');

      return;
    }

    const compare = (a, b) => {
      if (a.day < b.day) {
        return -1;
      }
      if (a.day > b.day) {
        return 1;
      }
      return 0;
    };

    data.sort(compare);

    // format the data
    data.forEach(d => {
      // eslint-disable-next-line no-param-reassign
      d.day = parseTime(d.day);
    });

    // Scale the range of the data
    x.domain(d3.extent(data, d => d.day));

    let minYValue;

    if (!isRating(attribute)) {
      minYValue = d3.min(data, d => d[attribute]);
      if (minYValue > 0) {
        minYValue = 0;
      }
    }

    y.domain(
      isRating(attribute)
        ? moodys
        : [minYValue, d3.max(data, d => d[attribute])],
    );

    // Add the valueline path
    svg
      .append('path')
      .data([data])
      .attr('class', 'line')
      .attr('d', valueline);

    // Add the X Axis
    if (isRating(attribute)) {
      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x));
    } else {
      svg
        .append('g')
        .attr('transform', `translate(0,${y(0)})`)
        .call(d3.axisBottom(x));
    }

    // Add the Y Axis
    const axis = isRating(attribute)
      ? d3.axisLeft(y)
      : d3.axisLeft(y).tickFormat(formatPercent);

    if (isRating(attribute)) {
      svg
        .append('g')
        .style('font-size', '7px')
        .call(axis);
    } else {
      svg
        .append('g')
        .style('font-size', '8px')
        .call(axis);
    }

    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', 0 - margin.top / 2)
      .attr('text-anchor', 'middle')
      .style('font-size', '17px')
      .style('font-weight', 'bold')
      .style('fill', 'rgb(8, 48, 107)')
      .text(title);

    // current date line
    if (urlDate) {
      // get i by date
      const xVal = parseTime(urlDate);

      svg
        .append('line')
        .attr('x1', x(xVal))
        .attr('y1', 500) // fixed
        .attr('x2', x(xVal))
        .attr('y2', 0) // fixed
        .attr('class', 'zeroline');

      svg
        .append('text')
        .attr('y', `-${x(xVal) + 4}`)
        .attr('x', 5)
        .attr('transform', 'rotate(90)')
        .attr('font-size', '14px')
        .text(dateFormatter(xVal))
        .attr('class', 'zerolinetext');
    }

    // tooltip
    const focus = svg
      .append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    focus
      .append('circle')
      .attr('r', 5)
      .attr('class', 'tooltip-left-cirlce');

    focus
      .append('rect')
      .attr('class', 'tooltip')
      .attr('width', 65)
      .attr('height', 25)
      .attr('x', 10)
      .attr('y', -13)
      .attr('rx', 4)
      .attr('ry', 4);

    focus
      .append('text')
      .attr('class', 'tooltip-date')
      .attr('x', 18)
      .attr('y', -4);

    focus
      .append('text')
      .attr('class', 'tooltip-value')
      .attr('x', 18)
      .attr('y', 8);

    focus
      .append('circle')
      .attr('r', 5)
      .attr('cx', 85)
      .attr('class', 'tooltip-right-cirlce');

    function mousemove() {
      const x0 = x.invert(d3.mouse(this)[0]);
      const i = bisectDate(data, x0, 1);
      const d0 = data[i - 1];
      const d1 = data[i];
      const d = x0 - d0.day > d1.day - x0 ? d1 : d0;

      if (x(d.day) > 790) {
        const divsToHide = document.getElementsByClassName(
          'tooltip-left-cirlce',
        );
        for (let i = 0; i < divsToHide.length; i++) {
          divsToHide[i].style.display = 'none';
        }

        const divsToShow = document.getElementsByClassName(
          'tooltip-right-cirlce',
        );
        for (let i = 0; i < divsToShow.length; i++) {
          divsToShow[i].style.display = 'block';
        }

        focus.attr(
          'transform',
          `translate(${x(d.day) - 85},${y(d[attribute])})`,
        );
      } else {
        const divsToHide = document.getElementsByClassName(
          'tooltip-right-cirlce',
        );
        for (let i = 0; i < divsToHide.length; i++) {
          divsToHide[i].style.display = 'none';
        }

        const divsToShow = document.getElementsByClassName(
          'tooltip-left-cirlce',
        );
        for (let i = 0; i < divsToShow.length; i++) {
          divsToShow[i].style.display = 'block';
        }

        focus.attr('transform', `translate(${x(d.day)},${y(d[attribute])})`);
      }

      const value =
        attribute === 'rating' ? d[attribute] : formatPercent(d[attribute]);

      focus.select('.tooltip-date').text(dateFormatter(d.day));
      focus.select('.tooltip-value').text(value);

      const sel = d3.select(this);
      sel.moveToFront();
    }

    svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', () => {
        focus.style('display', null);
      })
      .on('mouseout', () => {
        focus.style('display', 'none');
      })
      .on('mousemove', mousemove);
  };

  if (method === 'allRegionalValues') {
    method = 'regionalValues';
  }

  queue()
    .defer(
      request(method).post,
      JSON.stringify({
        query,
      }),
    )
    .await(dataGraphLoaded);
};
